/**
 * 广告配置-广告信息-广告配置（城市）
 * luxinwen
 * 2023-03-22
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content" v-grant="['OSP_ADVERT_MGM_SAVE', 'OSP_ADVERT_MGM_QUERY']">
        <Button type="primary" @click="addData" v-grant="'OSP_ADVERT_MGM_SAVE'">新增广告</Button>
        <div class="right-form" v-grant="'OSP_ADVERT_MGM_QUERY'">
          <Form :model="formData" inline>
            <FormItem>
              <Select class="width-s" v-model="formData.seatType" placeholder="请选择广告位置" clearable>
                <Option v-for="(value, key) in seatTypeList" :key="'seatType' + key" :value="key">{{ value }}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <Select class="width-s" v-model="formData.status" placeholder="请选择广告状态" clearable>
                <Option v-for="(value, key) in statusList" :key="'status' + key" :value="key">{{ value }}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="queryData">查询</Button>
            </FormItem>
          </Form>
        </div>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="pic">
            <img :src="params.row.activityMainPic" class="img-preview" @click="previewImage(params.row.activityMainPic)">
          </template>
          <template slot-scope="params" slot="status">
            <Tag :color="['warning', 'success', 'error'][params.row.status - 1]">{{ statusList[params.row.status] }}</Tag>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row, true)" v-grant="'OSP_ADVERT_MGM_VIEW'">查看</Button>
            <Button @click="editData(params.row, false)" v-grant="'OSP_ADVERT_MGM_UPDATE'">编辑</Button>
            <Button type="error" @click="removeData(params.row)" v-grant="'OSP_ADVERT_MGM_DELETE'">删除</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
    <modal-preview v-model="previewImageUrl"></modal-preview>
  </div>
</template>

<script>
  import { ADVERT_STATUS } from '@/util/enum';
  import editDrawer from './info-edit.vue';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        formData: {
          seatType: null, // 位置：1-首页顶部轮播
          status: null  // 广告状态：1-未开始，2-展示中，3-已结束
        },
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
          },
          {
            title: '广告图片',
            slot: 'pic'
          },
          {
            title: '广告状态',
            slot: 'status'
          },
          {
            title: '广告位置',
            key: 'seatTypeValue'
          },
          {
            title: '展示开始时间',
            key: 'startDate'
          },
          {
            title: '展示结束时间',
            key: 'endDate'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        dataId: '',
        seatTypeList: {
          1: '首页顶部轮播'
        },
        statusList: ADVERT_STATUS,
        previewImageUrl: ''
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_ADVERT_MGM_VIEW, this.$grant.OSP_ADVERT_MGM_UPDATE, this.$grant.OSP_ADVERT_MGM_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.dataId = this.$route.query.id;
      if (this.dataId) this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          regionId: Number(this.dataId),
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.advert.queryAdvertList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 新增数据
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {
          regionId: Number(this.dataId)
        };
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑
       */
      editData(row, onlyRoad = false) {
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 删除数据
       */
      removeData(row) {
        this.$confirm(`确定要删除所选数据吗？`).then(() => {
          this.$axios({
            url: this.$api.advert.deleteAdvert,
            data: {
              id: row.id
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 预览
       */
      previewImage(url) {
        this.previewImageUrl = url;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>