/**
 * 广告配置-广告信息-广告配置（城市）-编辑页
 * luxinwen
 * 2023-03-22
 */
<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="600" :before-close="beforeClose">
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="110">
        <FormItem label="广告图片" prop="activityMainPic">
          <sp-upload v-model="formData.activityMainPic" :img-width="1080" :img-height="540" :disabled="isShow"></sp-upload>
          <p class="text-tips">请上传宽高为1080*540的图片</p>
        </FormItem>
        <FormItem label="展示开始时间" prop="startDate">
          <DatePicker class="width-s" v-model="formData.startDate" type="date" :disabled="isShow" />
        </FormItem>
        <FormItem label="展示结束时间" prop="endDate">
          <DatePicker class="width-s" v-model="formData.endDate" type="date" :disabled="isShow" />
        </FormItem>
        <FormItem label="跳转类型" prop="skipType">
          <Select class="width-s" v-model="formData.skipType" placeholder="请选择跳转类型" clearable :disabled="isShow">
            <Option v-for="(value, key) in skipTypeList" :key="'skipType' + key" :value="Number(key)">{{ value }}</Option>
          </Select>
        </FormItem>
        <FormItem label="H5链接" prop="skipH5Url" v-if="formData.skipType === 0">
          <Input v-model.trim="formData.skipH5Url" class="width-l" maxlength="100" clearable :disabled="isShow" />
        </FormItem>
        <FormItem label="项目楼盘" prop="skipBuildingId" v-if="formData.skipType === 1">
          <Select class="width-s" v-model="formData.skipBuildingId" placeholder="请选择项目楼盘" clearable :disabled="isShow">
            <Option v-for="(item, index) in buildingList" :key="'building' + index" :value="item.id" :disabled="item.status === 2">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </Form>
      <div class="drawer-foot" v-show="!isShow">
        <Button type="primary" @click="submit">提交</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  import { ADVERT_SKIP_TYPE } from '@/util/enum';
  const formData = {
    activityMainPic: '',  // 广告图片
    endDate: '',  // 展示结束时间
    regionId: null, // 城市区域id
    seatType: null, // 位置：1-首页顶部轮播
    skipBuildingId: null, // 跳转项目楼盘id
    skipBuildingName: '', // 跳转项目楼盘名称
    skipH5Url: '',  // 跳转H5页面链接
    skipType: null, // 跳转类型：0,H5页面;1项目详情页面
    startDate: '',  // 展示开始时间
    title: ''  // 广告标题
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          activityMainPic: [
            {
              required: true,
              message: '请上传户型图',
              trigger: 'change'
            }
          ],
          startDate: [
            {
              type: 'date',
              required: true,
              message: '请选择展示开始时间',
              trigger: 'change'
            }
          ],
          endDate: [
            {
              type: 'date',
              required: true,
              message: '请选择展示结束时间',
              trigger: 'change'
            }
          ]
        },
        backupData: {},
        skipTypeList: ADVERT_SKIP_TYPE,
        buildingList: []
      };
    },
    computed: {
      title() {
        return {
          'add': '新增',
          'show': '查看',
          'edit': '编辑'
        }[this.flag] + '广告配置';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      isShow() {
        return this.flag === 'show';
      },
      isEdit() {
        return this.flag === 'edit';
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.initData(this.data, true);
        this.getBuildingData();
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            this.$confirm('确定要提交吗？').then(() => {
              let url = this.$api.advert.addAdvert;
              let data = Object.assign({}, this.formData);
              data.startDate = this.formatDate(data.startDate, 'yyyy-MM-dd');
              data.endDate = this.formatDate(data.endDate, 'yyyy-MM-dd');
              if (this.isEdit) {
                url = this.$api.advert.updateAdvert;
                data.id = this.data.id;
              }
              this.save(url, data);
            });
          }
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      },
      /**
       * 获取楼盘数据
       */
      getBuildingData() {
        let data = {
          cityId: this.formData.regionId,
          pageNum: 1,
          pageSize: 1000
        };
        this.$axios({
          url: this.$api.project.queryBuildingList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.buildingList = data.list || [];
        });
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>